<template>
    <NuxtLayout name="default">
        <div class="flex flex-col w-full px-8">
            <div class="flex justify-center w-full mx-auto flex-1 auth-width">
                <slot />
            </div>

            <layout-footer />
        </div>
    </NuxtLayout>
</template>

<style>
.auth-width {
    @apply max-w-md;
}

.auth-width:has(.layout-auth-wide) {
    @apply max-w-lg;
}
</style>
